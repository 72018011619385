<template>
  <div class="content fs-6 d-flex flex-column flex-column-fluid">
    <div class="toolbar">
      <div class="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
        <div
          class="d-flex flex-column align-items-start justify-content-center flex-wrap me-2"
        >
          <h1 class="text-dark fw-bolder my-1 fs-2">
            Formulaire de modification d'une agence
          </h1>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="card">
        <div class="collapse show">
          <form v-if="agency" class="form" @submit.prevent="createAgencyAdress">
            <div class="card-body border-top p-9">
              <div class="row mb-6">
                <label class="col-lg-2 col-form-label fw-bold fs-6">Nom</label>
                <div class="d-flex col-lg-10 fv-row">
                  <input
                    v-model="formAgency.name"
                    type="text"
                    class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Nom"
                  />
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-2 col-form-label fw-bold fs-6"
                  >Téléphone</label
                >
                <div class="d-flex col-lg-10 fv-row">
                  <input
                    v-model="formAgency.phone_number"
                    v-mask="'00 00 00 00 00'"
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="Premier téléphone"
                  />
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-2 col-form-label fw-bold fs-6"
                  >Adresse de l'agence</label
                >
                <div class="col-lg-10">
                  <AddressInput
                    :can-create-address="canCreateAgencyAddress"
                    :address-selected="sendAddress"
                    @addressCreated="catchAgencyAddressId"
                  />
                </div>
              </div>
            </div>
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <router-link
                :to="'/agencies/' + agency.id"
                class="btn btn-light btn-active-light-primary me-2"
              >
                Retour
              </router-link>
              <button type="submit" class="btn btn-primary">Modifier</button>
            </div>
          </form>
          <Loader v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useRoute } from "vue-router";
import Loader from "@/components/Loader";
import store from "@/store";
import AddressInput from "@/components/selects/AddressInput";

export default {
  name: "AgenciesForm",
  components: {
    Loader,
    AddressInput,
  },
  data: function () {
    return {
      formAgency: {
        id: "",
        name: "",
        phone_number: "",
        address: "",
        promoter: "",
      },
      canCreateAgencyAddress: false,
      sendAddress: {},
    };
  },
  computed: {
    ...mapGetters(["agency"]),
  },
  mounted() {
    const route = useRoute();
    if (route.params.id) {
      if (route.params.id != "add") {
        this.showAgency(route.params.id);
      } else {
        store.commit("agency", null);
      }
    }
  },
  methods: {
    ...mapActions(["postAgency"]),
    ...mapActions(["updateAgency"]),
    ...mapActions(["getAgency"]),
    async modifyAgency() {
      const res = await this.updateAgency(this.formAgency);
      if (res.status === 200) {
        this.$router.go(-1);
      }
    },
    async showAgency(id) {
      await this.getAgency(id);
      this.formAgency.id = this.agency.id;
      this.formAgency.name = this.agency.name;
      this.formAgency.phone_number = this.agency.phone_number;
      this.formAgency.promoter = this.agency.promoter.id;
      this.formAgency.address = this.agency.address.id;
      this.sendAddress = this.agency.address;
    },
    selectAgencyAddressOption(element) {
      this.formAgency.address = element;
    },
    async createAddressThenAgency() {
      await this.createAgencyAdress();
      this.canCreateAgencyAddress = false;
    },
    createAgencyAdress() {
      this.canCreateAgencyAddress = true;
    },
    async catchAgencyAddressId(value) {
      this.formAgency.address = value;
      this.canCreateAgencyAddress = false;
      this.modifyAgency();
    },
  },
};
</script>
